import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";
import {
  mutationPostPixInfractionReportByInfractionReportIdAcnowledge,
  mutationPostPixInfractionReportByInfractionReportIdCancel,
} from "api/dictv2-api";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import TableFilter from "components/organism/TableFilter/TableFilter";
import Endpoints from "constants/endpoints";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import InfractionReportSearchListModal from "./InfractionReportSearchListModal";

const InfractionReportSearchList = ({
  data,
  handleRefetch,
  inputFilter,
  loading,
  loadMore,
  setInputFilter,
  pagination,
  useLoadMore,
  isPixLimitValidatorRoute,
}) => {
  const [modal, setModal] = useState({
    state: false,
    id: "",
  });
  const [type, setType] = useState("");
  const { setOpen } = useOtp();
  const [filteredData, setFilteredData] = useState(data);
  const { accountSelected, accountType } = useAccount();
  const isSandboxEnvironment = Endpoints.Environment === "Sandbox";

  const { mutate: mutateCancel } = useMutation((e) =>
    mutationPostPixInfractionReportByInfractionReportIdCancel(e)
  );

  const { mutate: mutateReceive } = useMutation((e) =>
    mutationPostPixInfractionReportByInfractionReportIdAcnowledge(e)
  );

  const handleCancel = (infractionReportId) => {
    if (isSandboxEnvironment) {
      onSubmit({
        type: "CANCEL",
        infractionReportId,
      });
    } else {
      setType("CANCEL");
      setModal({ ...modal, infractionReportId });
      setOpen(true);
    }
  };

  // const handleReceive = (infractionReportId) => {
  //   setType("RECEIVE");
  //   setModal({ ...modal, infractionReportId });
  //   setOpen(true);
  // };

  const ispb = accountSelected.markups
    .find((e) => e.indexOf("ISPB") !== -1)
    .split(":")[1];

  const onSubmit = useCallback(
    (values, formik) => {
      type === "CANCEL" || values.type === "CANCEL"
        ? mutateCancel(
            {
              otp: values.otp,
              infractionReportId:
                modal.infractionReportId || values.infractionReportId,
              accountType: accountType,
            },
            {
              onSuccess: (data) => {
                data.errors
                  ? toast.error(data.errors[0].message)
                  : toast.success(toastMessages.requestSuccess);

                formik.resetForm();
                setOpen(false);
                handleRefetch();
              },

              onSettled: () => {
                formik.setSubmitting(false);
              },
            }
          )
        : mutateReceive(
            {
              otp: values.otp,
              infractionReportId: modal.infractionReportId,
              accountType: accountType,
            },
            {
              onSuccess: (data) => {
                data.errors
                  ? toast.error(data.errors[0].message)
                  : toast.success(toastMessages.requestSuccess);
                formik.resetForm();
                setOpen(false);
              },

              onSettled: () => {
                formik.setSubmitting(false);
              },
            }
          );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      type,
      mutateCancel,
      modal.infractionReportId,
      accountType,
      mutateReceive,
      setOpen,
    ]
  );

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () =>
          setModal({
            state: true,
            // endToEndId: item.endToEndId,
            id: item.id,
          }),
        icon: InfoIcon,
        tooltip: "More info",
      },
    ];

    if (!isPixLimitValidatorRoute && accountType !== "Merchant") {
      conditions.push({
        onClick: () => handleCancel(item.id),
        icon: CloseIcon,
        tooltip: "Cancel",
        disabled:
          (item.isCounterParty && item.creditedParticipant !== ispb) ||
          (item.isReporter && item.debitedParticipant !== ispb) ||
          item.status === "CANCELLED",
      });
    }

    return conditions;
  };

  useEffect(() => {
    if (inputFilter) {
      setFilteredData(
        data.filter((infraction) => {
          return infraction.transactionId === inputFilter;
        })
      );
    } else {
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, data]);

  return (
    <>
      <Formik initialValues={{}}>
        {({ values, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <DinamicTable
              filterChildren={
                <TableFilter
                  inputLabel="Filter by Transaction Id"
                  setFilterValue={setInputFilter}
                  inputWidth="290px"
                />
              }
              canDownloadCsv={true}
              loading={loading}
              data={filteredData}
              defaultSort={{
                key: "status",
                orientation: "desc",
              }}
              tableSchema={[
                {
                  header: "Id",
                  key: "id",
                  tooltip: true,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  },
                },
                {
                  header: "Transaction Id",
                  key: "transactionId",
                  tooltip: true,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  },
                },
                {
                  header: "Reporter Part.",
                  key: "reporterParticipant",
                  sort: true,
                },
                {
                  header: "Counterparty Part.",
                  key: "counterpartyParticipant",
                  sort: true,
                },
                {
                  header: "Situation Type",
                  key: "situationType",
                  tooltip: true,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  },
                },
                {
                  header: "Status",
                  key: "status",
                  sort: true,
                },
              ]}
              getExtraActions={(data) => conditionalAction(data)}
            />
            {useLoadMore && (
              <Box width="100%" mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  disabled={!Boolean(pagination?.hasMoreElements)}
                  onClick={() => loadMore()}
                >
                  Load more results
                </Button>
              </Box>
            )}
            <InfractionReportSearchListModal
              ispb={ispb}
              handleRefetch={handleRefetch}
              loadingSearch={loading}
              modal={modal}
              setModal={setModal}
              data={data}
              hideActions={isPixLimitValidatorRoute}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InfractionReportSearchList;
