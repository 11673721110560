import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import InputIcon from "@mui/icons-material/Input";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";

export default function EntrySearchList({
  data,
  loading,
  setDataModalDelete,
  setDataModalUpdate,
  setDataModalInfo,
}) {
  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () =>
          setDataModalInfo({
            state: true,
            data: item,
          }),
        icon: InfoIcon,
        tooltip: "More info",
      },
      {
        onClick: () =>
          setDataModalUpdate({
            state: true,
            data: item,
          }),
        icon: InputIcon,
        tooltip: "Update Key",
      },
      {
        onClick: () =>
          setDataModalDelete({
            state: true,
            data: item,
          }),
        icon: DeleteIcon,
        tooltip: "Delete Key",
      },
    ];
    return conditions;
  };

  const formatAccount = (item) => {
    return (
      item.account?.branch +
      " | " +
      item.account?.accountType +
      " | " +
      item.account?.accountNumber
    );
  };

  return (
    <DinamicTable
      loading={loading}
      data={data}
      defaultSort={{
        key: "key",
        orientation: "desc",
      }}
      tableSchema={[
        {
          header: "Key",
          key: "key",
          tooltip: true,
          sort: true,
          style: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "noWrap",
          },
        },
        {
          header: "Key Type",
          key: "keyType",
          sort: true,
        },
        {
          header: "Account",
          extractItemValue: (item) => formatAccount(item),
          key: "account.accountNumber",
          sort: true,
        },
        {
          header: "Owner",
          key: "owner.name",
          sort: true,
        },
        {
          header: "Creation Date",
          key: "creationDate",
          formatValue: (value) => moment(value).format("DD/MM/YYYY H:mm:ss"),
          sort: true,
        },
      ]}
      getExtraActions={(data) => conditionalAction(data)}
    />
  );
}
