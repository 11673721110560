import { useTheme, Card } from "@mui/material";
import React from "react";

const StackCard = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Card
      {...props}
      sx={{
        background: props.background || theme.palette.grey[100],
        padding: 2,
        boxShadow:
          "0px 0px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        ...props.sx,
      }}
    >
      {children}
    </Card>
  );
};

export default StackCard;
