import InfoIcon from "@mui/icons-material/Info";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import ClaimSearchListModal from "./ClaimSearchListModal";
import { Box, Button } from "@mui/material";

const ClaimSearchList = ({
  data,
  loading,
  loadMore,
  handleRefetch,
  useLoadMore,
  pagination,
}) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const onViewClick = (item) => {
    setModalData(item);
    setModal(true);
  };

  return (
    <>
      <Formik initialValues={{}}>
        {({ values, ...props }) => (
          <Form>
            <DinamicTable
              canDownloadCsv={true}
              data={data}
              loading={loading}
              defaultSort={{
                key: "createTimestamp",
                orientation: "desc",
              }}
              tableSchema={[
                {
                  header: "Key",
                  key: "key",
                  tooltip: true,
                  sort: true,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  },
                },
                {
                  header: "Claim Type",
                  key: "type",
                  sort: true,
                },
                {
                  header: "Key Type",
                  key: "keyType",
                  sort: true,
                },
                {
                  header: "Status",
                  key: "status",
                  sort: true,
                },
              ]}
              extraActions={[
                {
                  onClick: (item) => onViewClick(item),
                  icon: InfoIcon,
                  tooltip: "More info",
                },
              ]}
            />
            {useLoadMore && (
              <Box width="100%" mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  disabled={!Boolean(pagination?.hasMoreElements)}
                  onClick={() => loadMore()}
                >
                  Load more results
                </Button>
              </Box>
            )}
            <ClaimSearchListModal
              modal={modal}
              setModal={setModal}
              data={modalData}
              handleRefetch={handleRefetch}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ClaimSearchList;
